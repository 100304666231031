define("discourse/plugins/discourse-encrypt/discourse/components/modal/reset-key-pair", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-encrypt/lib/database", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _database, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.loadStats}}
    @title={{i18n "encrypt.reset.title"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <ConditionalLoadingSection @isLoading={{this.isLoadingStats}}>
        {{#if this.encryptedPmsCount}}
          <p>{{i18n "encrypt.reset.instructions"}}</p>
          <p>
            {{html-safe
              (i18n
                "encrypt.reset.instructions_lost_pms" count=this.encryptedPmsCount
              )
            }}
            {{html-safe
              (i18n
                "encrypt.reset.confirm_instructions"
                username=this.currentUser.username
              )
            }}
          </p>
          <Input @type="text" @value={{this.confirmation}} />
        {{else}}
          <p>{{i18n "encrypt.reset.instructions_safe"}}</p>
        {{/if}}
      </ConditionalLoadingSection>
    </:body>
  
    <:footer>
      <DButton
        class="btn btn-danger"
        @icon="trash-can"
        @label="encrypt.reset.title"
        @action={{this.reset}}
        @disabled={{this.disabled}}
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "htvnTQwb",
    "block": "[[[8,[39,0],[[4,[38,2],[[30,0,[\"loadStats\"]]],null]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"encrypt.reset.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@isLoading\"],[[30,0,[\"isLoadingStats\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"encryptedPmsCount\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.reset.instructions\"],null]],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,5],[[28,[37,1],[\"encrypt.reset.instructions_lost_pms\"],[[\"count\"],[[30,0,[\"encryptedPmsCount\"]]]]]],null]],[1,\"\\n          \"],[1,[28,[35,5],[[28,[37,1],[\"encrypt.reset.confirm_instructions\"],[[\"username\"],[[30,0,[\"currentUser\",\"username\"]]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,6],null,[[\"@type\",\"@value\"],[\"text\",[30,0,[\"confirmation\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.reset.instructions_safe\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn btn-danger\"]],[[\"@icon\",\"@label\",\"@action\",\"@disabled\"],[\"trash-can\",\"encrypt.reset.title\",[30,0,[\"reset\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n    \"],[8,[39,8],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"conditional-loading-section\",\"if\",\"html-safe\",\"input\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/reset-key-pair.hbs",
    "isStrictMode": false
  });
  class ResetKeyPair extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isLoadingStats", [_tracking.tracked], function () {
      return true;
    }))();
    #isLoadingStats = (() => (dt7948.i(this, "isLoadingStats"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "inProgress", [_tracking.tracked], function () {
      return false;
    }))();
    #inProgress = (() => (dt7948.i(this, "inProgress"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "encryptedPmsCount", [_tracking.tracked]))();
    #encryptedPmsCount = (() => (dt7948.i(this, "encryptedPmsCount"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "confirmation", [_tracking.tracked], function () {
      return "";
    }))();
    #confirmation = (() => (dt7948.i(this, "confirmation"), void 0))();
    get disabled() {
      return this.isLoadingStats || this.inProgress || this.encryptedPmsCount > 0 && this.currentUser.username !== this.confirmation;
    }
    async loadStats() {
      try {
        const result = await (0, _ajax.ajax)("/encrypt/stats", {
          data: {
            user_id: this.args.model.user.id
          }
        });
        if (result.encrypted_pms_count > 0) {
          this.encryptedPmsCount = result.encrypted_pms_count;
        }
      } finally {
        this.isLoadingStats = false;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "loadStats", [_object.action]))();
    async reset() {
      this.inProgress = true;
      try {
        await Promise.all([(0, _ajax.ajax)("/encrypt/reset", {
          type: "POST",
          data: {
            user_id: this.args.model.user.id
          }
        }), _database.deleteDb]);
        this.currentUser.setProperties({
          encrypt_public: null,
          encrypt_private: null
        });
        this.appEvents.trigger("encrypt:status-changed");
        this.args.closeModal();
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.inProgress = false;
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "reset", [_object.action]))();
  }
  _exports.default = ResetKeyPair;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ResetKeyPair);
});